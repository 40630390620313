import React from "react"
import Image from "./imageComponent"
import { Link } from "gatsby"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

class ProjectCard extends React.Component {
  componentDidMount() {
    const { project, styles } = this.props
    const slider = document.getElementById(styles.projectFiltersContainer)
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 2 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    })
  }

  render() {
    const { project, styles } = this.props
    const altText = project.title + " " + project.services.toString()

    return (
      <div
        className={styles.projectCard}
        onDragStart={() => {
          return false
        }}
      >
        <ScrollAnimation
          animateIn="animate__fadeIn"
          animateOnce={true}
          duration={0.5}
        >
          <Link to={this.props.link}>
            <Image
              alt={altText}
              imgClass={styles.projectImg}
              fileName={project.imgFile + ".jpg"}
              animated={false}
            />
          </Link>
        </ScrollAnimation>
        <div
          className={styles.projectFiltersContainer}
          id={styles.projectFiltersContainer}
        >
          {project.services.map((service, index) => {
            let link =
              "/services/" +
              project.category.toLowerCase() +
              "/" +
              service.replace(/ /g, "-").toLowerCase()
            return (
              <div className="projectFilterService" key={index}>
                <span className={styles.projectFilterText}>
                  <Link to={link.replace(".", "")} style={{ color: "#ffffff" }}>
                    {service}
                  </Link>
                </span>
              </div>
            )
          })}
        </div>
        <div className={styles.projectTitle}>{project.title}</div>
        {
          //<p className={styles.projectDescription}>{project.description}</p>
        }
        <Link to={this.props.link}>
          <button className="buttonStyle2">SEE PROJECT</button>
        </Link>
      </div>
    )
  }
}

export default ProjectCard
