// extracted by mini-css-extract-plugin
export const portfolioContainer = "portfolio-module--portfolioContainer--qb0gX";
export const portfolioBody = "portfolio-module--portfolioBody--1JMVi";
export const filtersContainer = "portfolio-module--filtersContainer--1sREP";
export const projectFiltersContainer = "portfolio-module--projectFiltersContainer--1cUQ9";
export const subCategoryFilter = "portfolio-module--subCategoryFilter--31g4Y";
export const subCategoryFilterBtn = "portfolio-module--subCategoryFilterBtn--2BrR3";
export const dropDownContent = "portfolio-module--dropDownContent--2BjUv";
export const subCategoryItem = "portfolio-module--subCategoryItem--SqN8O";
export const subCategoryItem__active = "portfolio-module--subCategoryItem__active--2WJQx";
export const projectFilterText = "portfolio-module--projectFilterText--2x54Z";
export const portfolioGrid = "portfolio-module--portfolioGrid--XzESv";
export const projectCard = "portfolio-module--projectCard--3Xth_";
export const projectImg = "portfolio-module--projectImg--Ov5Zq";
export const projectTitle = "portfolio-module--projectTitle--2wbsT";
export const projectDescription = "portfolio-module--projectDescription--2pE5T";